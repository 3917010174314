var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "clause" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" },
          [
            _c(
              "vs-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.openModalClauses(_vm.defaultClauseObject)
                  },
                },
              },
              [_vm._v("Adicionar Condição")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "clauses-list" },
        [
          _vm.clausesList.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    attrs: { data: _vm.clausesList },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: tr } },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].Name } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(data[indextr].Name) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].ContractualTerm
                                          .CreateDate,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              data[indextr].ContractualTerm
                                                .CreateDate,
                                              "DD/MM/YYYY"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c("vs-td", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data[indextr].ContractualTerm
                                            .IsDeleted
                                            ? "Inativa"
                                            : "Ativa"
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticStyle: {
                                                width: "200px",
                                                "z-index": "53001 !important",
                                              },
                                            },
                                            [
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.openModalClauses(
                                                        data[indextr]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Editar"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.openModalDelete(
                                                        data[indextr].Name,
                                                        data[indextr].Id,
                                                        !data[indextr]
                                                          .ContractualTerm
                                                          .IsDeleted ||
                                                          data[indextr].IsActive
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Inativar/Ativar"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      3024533123
                    ),
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h4", { staticClass: "ml-2 mb-2" }, [
                        _vm._v("Condições Particulares"),
                      ]),
                    ]),
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v(" Condição ")]),
                        _c("vs-th", [_vm._v(" Data de cadastro ")]),
                        _c("vs-th", [_vm._v(" Status ")]),
                        _c("vs-th", [_vm._v(" Ações ")]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-particular-clauses",
          attrs: { title: "", active: _vm.modalClauses },
          on: {
            "update:active": function ($event) {
              _vm.modalClauses = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "p-2 mb-5" }, [
            _vm._v("Condição Particular"),
          ]),
          _c(
            "div",
            [
              _vm.modalClauses
                ? _c("objeto-tags", {
                    attrs: { objectData: _vm.clauseObject },
                    on: { getClauses: _vm.getClauses },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: "", active: _vm.modalDelete },
          on: {
            "update:active": function ($event) {
              _vm.modalDelete = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full flex justify-center mb-10" },
              [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          Deseja realmente " +
                      _vm._s(_vm.clauseIsDeleted ? "ativar" : "inativar") +
                      " a\n          condição "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.clauseName))]),
                  _vm._v("?\n        "),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        return _vm.cancelDelete()
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.confirmDelete(_vm.clauseId)
                      },
                    },
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }