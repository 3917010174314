var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.details && _vm.objectDetails
    ? _c(
        "section",
        { staticClass: "modality-detail" },
        [
          _vm.$route.params.modalidadeId &&
          !_vm.complementary &&
          _vm.objectDetails.hasOwnProperty("Id")
            ? _c(
                "vs-row",
                [
                  _c("vs-col", { attrs: { "js-justify": "flex-end" } }, [
                    _c("h5", { staticClass: "update-date" }, [
                      _vm._v(
                        "\n        Atualizado em\n        " +
                          _vm._s(
                            _vm.$utils.dateToDDMMYYYY(
                              (_vm.objectDetails || {}).UpdateDate
                            )
                          ) +
                          "\n      "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "12", "vs-sm": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Nome da modalidade" },
                    model: {
                      value: _vm.objectDetails.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "Name", $$v)
                      },
                      expression: "objectDetails.Name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "4", "vs-sm": "12" } },
                [
                  _c("p", { staticClass: "label-input" }, [
                    _vm._v("Valor máximo segurado"),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input hasValue vs-input--input normal",
                    model: {
                      value: _vm.objectDetails.MaxInsuredAmountValue,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.objectDetails,
                          "MaxInsuredAmountValue",
                          $$v
                        )
                      },
                      expression: "objectDetails.MaxInsuredAmountValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-w": "4", "vs-sm": "12" } },
                [
                  _c("p", { staticClass: "label-input" }, [
                    _vm._v("Valor máximo aprovado automaticamente"),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input hasValue vs-input--input normal",
                    model: {
                      value: _vm.objectDetails.MaxInsuredAmountAutomaticValue,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.objectDetails,
                          "MaxInsuredAmountAutomaticValue",
                          $$v
                        )
                      },
                      expression:
                        "objectDetails.MaxInsuredAmountAutomaticValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-w": "4", "vs-sm": "12" } },
                [
                  _c("p", { staticClass: "label-input" }, [
                    _vm._v("Prêmio mínimo"),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input hasValue vs-input--input normal",
                    model: {
                      value: _vm.objectDetails.MinInsurancePremium,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "MinInsurancePremium", $$v)
                      },
                      expression: "objectDetails.MinInsurancePremium",
                    },
                  }),
                ],
                1
              ),
              false
                ? _c(
                    "vs-col",
                    { attrs: { "vs-w": "3", "vs-sm": "12" } },
                    [
                      _c("p", { staticClass: "label-input" }, [
                        _vm._v("Importância Segurada mínima"),
                      ]),
                      _c("currency-input", {
                        staticClass:
                          "w-full vs-inputx vs-input--input hasValue vs-input--input normal",
                        attrs: { value: 0, readonly: true },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "6", "vs-sm": "12", "vs-xs": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Max. dias retroativos" },
                    model: {
                      value: _vm.objectDetails.MaxRetroactivityDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "MaxRetroactivityDays", $$v)
                      },
                      expression: "objectDetails.MaxRetroactivityDays",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-lg": "6", "vs-sm": "12", "vs-xs": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Max. dias futuros" },
                    model: {
                      value: _vm.objectDetails.MaxFutureDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "MaxFutureDays", $$v)
                      },
                      expression: "objectDetails.MaxFutureDays",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-w": "4", "vs-sm": "12", "vs-xs": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Vigência mínima (dias)" },
                    model: {
                      value: _vm.objectDetails.MinPeriodInDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "MinPeriodInDays", $$v)
                      },
                      expression: "objectDetails.MinPeriodInDays",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-w": "4", "vs-sm": "12", "vs-xs": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Vigência máxima (dias)" },
                    model: {
                      value: _vm.objectDetails.MaxPeriodInDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "MaxPeriodInDays", $$v)
                      },
                      expression: "objectDetails.MaxPeriodInDays",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                { attrs: { "vs-w": "4", "vs-sm": "12", "vs-xs": "12" } },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { label: "Código de referência da seguradora" },
                    model: {
                      value: _vm.objectDetails.Ref,
                      callback: function ($$v) {
                        _vm.$set(_vm.objectDetails, "Ref", $$v)
                      },
                      expression: "objectDetails.Ref",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.objectDetails.CanUseModalityTax
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "4", "vs-sm": "12", "vs-xs": "12" } },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Selecione o tipo"),
                      ]),
                      _c("v-select", {
                        staticClass: "w-full",
                        attrs: {
                          label: "name",
                          size: "large",
                          placeholder: "Tipo",
                          options: _vm.options,
                          value: _vm.objectDetails.CalculationType,
                          reduce: (option) => option.value,
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        model: {
                          value: _vm.objectDetails.CalculationType,
                          callback: function ($$v) {
                            _vm.$set(_vm.objectDetails, "CalculationType", $$v)
                          },
                          expression: "objectDetails.CalculationType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "4", "vs-sm": "12", "vs-xs": "12" } },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { label: "Valor %" },
                        model: {
                          value: _vm.objectDetails.ModalityTaxValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.objectDetails, "ModalityTaxValue", $$v)
                          },
                          expression: "objectDetails.ModalityTaxValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  staticClass: "mt-8 md:pb-3",
                  attrs: { "vs-w": "12", "vs-sm": "12" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.objectDetails.IsDeleted,
                          callback: function ($$v) {
                            _vm.$set(_vm.objectDetails, "IsDeleted", $$v)
                          },
                          expression: "objectDetails.IsDeleted",
                        },
                      }),
                      _c("label", [_vm._v("Inativar modalidade")]),
                    ],
                    1
                  ),
                  _vm.objectDetails.IsDeleted
                    ? _c(
                        "div",
                        { staticStyle: { margin: "10px" } },
                        [
                          _c(
                            "label",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(" Observação:")]
                          ),
                          _c("br"),
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "Observação",
                            },
                            model: {
                              value: _vm.objectDetails.Observation,
                              callback: function ($$v) {
                                _vm.$set(_vm.objectDetails, "Observation", $$v)
                              },
                              expression: "objectDetails.Observation",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.objectDetails.EnabledForAutomaticEmission,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "EnabledForAutomaticEmission",
                              $$v
                            )
                          },
                          expression:
                            "objectDetails.EnabledForAutomaticEmission",
                        },
                      }),
                      _c("label", [
                        _vm._v("Modalidade habilitada para emissão automática"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.objectDetails.IsComplementaryModality,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "IsComplementaryModality",
                              $$v
                            )
                          },
                          expression: "objectDetails.IsComplementaryModality",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          "Esta modalidade está atuando como modalidade complementar"
                        ),
                      ]),
                    ],
                    1
                  ),
                  true
                    ? _c(
                        "div",
                        { staticClass: "flex pt-3" },
                        [
                          _c("vs-switch", {
                            staticClass: "mr-3",
                            attrs: {
                              disabled:
                                _vm.objectDetails.IsComplementaryModality,
                            },
                            model: {
                              value: _vm.objectDetails.HasComplementaryModality,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.objectDetails,
                                  "HasComplementaryModality",
                                  $$v
                                )
                              },
                              expression:
                                "objectDetails.HasComplementaryModality",
                            },
                          }),
                          _c("label", [
                            _vm._v(
                              "Esta modalidade permite a inclusão de modalidade\n          complementar"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value:
                            _vm.objectDetails
                              .DisabledForRestrictiveBranchActivity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "DisabledForRestrictiveBranchActivity",
                              $$v
                            )
                          },
                          expression:
                            "objectDetails.DisabledForRestrictiveBranchActivity",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          "Esta modalidade é restrita para os tomadores com ramos\n          restritos"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.objectDetails.EqualizeValidityDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "EqualizeValidityDate",
                              $$v
                            )
                          },
                          expression: "objectDetails.EqualizeValidityDate",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          "As vigências entre as modalidades e cobertura devem ser\n          iguais"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.objectDetails.IgnoreBranchWhenInsuredIsPF,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "IgnoreBranchWhenInsuredIsPF",
                              $$v
                            )
                          },
                          expression:
                            "objectDetails.IgnoreBranchWhenInsuredIsPF",
                        },
                      }),
                      _c("label", [
                        _vm._v("Permite emissão com segurado Pessoa Física"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        on: {
                          change: function ($event) {
                            _vm.objectDetails.CanHaveBeneficiaryPF = false
                          },
                        },
                        model: {
                          value: _vm.objectDetails.CanHaveBeneficiary,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "CanHaveBeneficiary",
                              $$v
                            )
                          },
                          expression: "objectDetails.CanHaveBeneficiary",
                        },
                      }),
                      _c("label", [_vm._v("Permite inclusão de beneficiário")]),
                    ],
                    1
                  ),
                  !!_vm.objectDetails.CanHaveBeneficiary
                    ? _c(
                        "div",
                        { staticClass: "flex pt-3" },
                        [
                          _c("vs-switch", {
                            staticClass: "mr-3",
                            attrs: {
                              value: !!_vm.objectDetails.CanHaveBeneficiaryPF,
                            },
                            on: {
                              input: function ($event) {
                                _vm.objectDetails.CanHaveBeneficiaryPF = $event
                                _vm.$forceUpdate()
                              },
                            },
                          }),
                          _c("label", [
                            _vm._v(
                              "Permite inclusão de beneficiário Pessoa Física"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.details.EnableToMonitoring,
                          callback: function ($$v) {
                            _vm.$set(_vm.details, "EnableToMonitoring", $$v)
                          },
                          expression: "details.EnableToMonitoring",
                        },
                      }),
                      _c("label", [_vm._v("Esta modalidade é monitorada")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.details.EnableToSteppin,
                          callback: function ($$v) {
                            _vm.$set(_vm.details, "EnableToSteppin", $$v)
                          },
                          expression: "details.EnableToSteppin",
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          "Esta modalidade pode realizar a retomada do objeto (Step-in)"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        model: {
                          value: _vm.details.EnableToBigRisk,
                          callback: function ($$v) {
                            _vm.$set(_vm.details, "EnableToBigRisk", $$v)
                          },
                          expression: "details.EnableToBigRisk",
                        },
                      }),
                      _c("label", [
                        _vm._v("Esta modalidade é de grande risco"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex pt-3" },
                    [
                      _c("vs-switch", {
                        staticClass: "mr-3",
                        on: {
                          change: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                        model: {
                          value: _vm.objectDetails.EnableAutomaticCancel,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "EnableAutomaticCancel",
                              $$v
                            )
                          },
                          expression: "objectDetails.EnableAutomaticCancel",
                        },
                      }),
                      _c("label", [
                        _vm._v("Cancelamento automático de apólices vencidas"),
                      ]),
                    ],
                    1
                  ),
                  _vm.Newe
                    ? _c(
                        "div",
                        { staticClass: "flex pt-3" },
                        [
                          _c("vs-switch", {
                            staticClass: "mr-3",
                            on: {
                              change: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                            model: {
                              value: _vm.objectDetails.CanUseModalityTax,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.objectDetails,
                                  "CanUseModalityTax",
                                  $$v
                                )
                              },
                              expression: "objectDetails.CanUseModalityTax",
                            },
                          }),
                          _c("label", [
                            _vm._v("Permite de uso de taxa por modalidade"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm.objectDetails.EnableAutomaticCancel
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "md:w-1/2 w-full" },
                    [
                      _c("vs-input", {
                        attrs: { label: "Nº dias após vencimento" },
                        model: {
                          value: _vm.objectDetails.DaysForAutomaticCancel,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.objectDetails,
                              "DaysForAutomaticCancel",
                              $$v
                            )
                          },
                          expression: "objectDetails.DaysForAutomaticCancel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { attrs: { "vs-type": "flex", "vs-justify": "end" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingButton },
                      on: {
                        click: function ($event) {
                          return _vm.saveDetails(_vm.objectDetails)
                        },
                      },
                    },
                    [_vm._v("Salvar detalhes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }