var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coverage" },
    [
      _c(
        "vs-row",
        { staticClass: "justify-between pt-0", attrs: { "vs-type": "flex" } },
        [
          _c("h4", { staticClass: "font-bold" }, [
            _vm._v("Coberturas Adicionais"),
          ]),
          _c(
            "vs-button",
            {
              on: {
                click: function ($event) {
                  return _vm.openModalCoverage(_vm.coverageDefault)
                },
              },
            },
            [_vm._v("\n      Adicionar Cobertura\n    ")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "coverage-list" },
        [
          (_vm.coverageParams || []).length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    attrs: { data: _vm.coverageParams || [] },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: tr } },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].Name } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(data[indextr].Name) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].CreateDate },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              data[indextr].CreateDate,
                                              "DD/MM/YYYY"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].Ref } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(data[indextr].Ref) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr]
                                          .CoverageCalculationJSONParam,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            data[indextr].calculationCoverage
                                              .name
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr]
                                          .CoverageCalculationJSONParam,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            data[indextr].calculationCoverage
                                              .value
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c("vs-td", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data[indextr].IsDeleted
                                            ? "Inativa"
                                            : "Ativa"
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticStyle: {
                                                width: "200px",
                                                "z-index": "53001 !important",
                                              },
                                            },
                                            [
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.openModalCoverage(
                                                        data[indextr]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Editar"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.openModalDelete(
                                                        data[indextr].Name,
                                                        data[indextr].Id,
                                                        data[indextr].IsDeleted
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Inativar/Ativar"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      3414501464
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v(" Cobertura ")]),
                        _c("vs-th", [_vm._v(" Data de cadastro ")]),
                        _c("vs-th", [_vm._v(" Código de referência ")]),
                        _c("vs-th", [_vm._v(" Tipo de Cálculo da Cobertura ")]),
                        _c("vs-th", [
                          _vm._v(" Valor do Cálculo da Cobertura "),
                        ]),
                        _c("vs-th", [_vm._v(" Status ")]),
                        _c("vs-th", [_vm._v(" Ações ")]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-aditional-coverage",
          attrs: { title: "Cobertura Adicional", active: _vm.modalCoverage },
          on: {
            "update:active": function ($event) {
              _vm.modalCoverage = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "horizontal-tabs-components",
                { attrs: { tabs: _vm.tabs } },
                [
                  _c(
                    "template",
                    { slot: "tab-content-0" },
                    [
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { staticClass: "w-1/2" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: "Nome da cobertura" },
                                model: {
                                  value: _vm.coverageObject.Name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.coverageObject, "Name", $$v)
                                  },
                                  expression: "coverageObject.Name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { staticClass: "w-1/2" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: "Código de referência" },
                                model: {
                                  value: _vm.coverageObject.Ref,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.coverageObject, "Ref", $$v)
                                  },
                                  expression: "coverageObject.Ref",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { staticClass: "w-1/2" },
                            [
                              _c("p", [_vm._v("Tipo de cálculo")]),
                              _vm.coverageObject.calculationCoverage
                                ? _c("v-select", {
                                    staticClass: "w-full",
                                    attrs: {
                                      options: [
                                        "AggravationRate",
                                        "DistributionRate",
                                      ],
                                      placeholder: "",
                                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                    },
                                    model: {
                                      value:
                                        _vm.coverageObject.calculationCoverage
                                          .name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.coverageObject
                                            .calculationCoverage,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "coverageObject.calculationCoverage.name",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { staticClass: "w-1/2" },
                            [
                              _vm.coverageObject.calculationCoverage
                                ? _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: { label: "Valor do cálculo" },
                                    model: {
                                      value:
                                        _vm.coverageObject.calculationCoverage
                                          .value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.coverageObject
                                            .calculationCoverage,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "coverageObject.calculationCoverage.value",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingButton,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveCoverage(
                                        _vm.coverageObject
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Salvar")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "tab-content-1" },
                    [
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { staticClass: "md:w-5/12 w-full" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: "Referência da seguradora" },
                                model: {
                                  value: _vm.object.RefIntegration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "RefIntegration", $$v)
                                  },
                                  expression: "object.RefIntegration",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.object.Tags, function (item, index) {
                        return _c(
                          "vs-row",
                          { key: index },
                          [
                            _c(
                              "vs-col",
                              { staticClass: "md:w-5/12 w-full" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { label: "Descrição" },
                                  model: {
                                    value: item.Label,
                                    callback: function ($$v) {
                                      _vm.$set(item, "Label", $$v)
                                    },
                                    expression: "item.Label",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { staticClass: "md:w-6/12 w-full pb-5" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { label: "Tag", value: item.Name },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setName(
                                        item,
                                        $event.target.value,
                                        5
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { staticClass: "md:w-1/12 w-full m-auto" },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "flat",
                                    icon: "close",
                                    size: "large",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.removeTag(item, index, 5)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "add-tag py-1",
                                  attrs: {
                                    type: "border",
                                    icon: "add",
                                    "icon-after": true,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTag(5)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Incluir TAG\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        { staticClass: "mb-5" },
                        [
                          _c("vs-switch", {
                            model: {
                              value: _vm.object.EnableToProposal,
                              callback: function ($$v) {
                                _vm.$set(_vm.object, "EnableToProposal", $$v)
                              },
                              expression: "object.EnableToProposal",
                            },
                          }),
                          _c("label", { staticClass: "ml-2" }, [
                            _vm._v(" Habilitar na Proposta"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            [
                              _c("jodit-editor", {
                                attrs: {
                                  config: _vm.joditConfig,
                                  id: "objeto",
                                },
                                model: {
                                  value: _vm.object.Value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.object, "Value", $$v)
                                  },
                                  expression: "object.Value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingButton,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveObject(5)
                                    },
                                  },
                                },
                                [_vm._v("Salvar objeto")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "tab-content-2" },
                    [
                      _vm._l(_vm.condicaoGeral.Tags, function (item, index) {
                        return _c(
                          "vs-row",
                          { key: index },
                          [
                            _c(
                              "vs-col",
                              { staticClass: "md:w-5/12 w-full" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { label: "Descrição" },
                                  model: {
                                    value: item.Label,
                                    callback: function ($$v) {
                                      _vm.$set(item, "Label", $$v)
                                    },
                                    expression: "item.Label",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { staticClass: "md:w-6/12 w-full pb-5" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { label: "Tag", value: item.Name },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setName(
                                        item,
                                        $event.target.value,
                                        1
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { staticClass: "md:w-1/12 w-full m-auto" },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "flat",
                                    icon: "close",
                                    size: "large",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.removeTag(item, index, 1)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { staticClass: "md:w-5/12 w-full" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: "Referência da seguradora" },
                                model: {
                                  value: _vm.condicaoGeral.RefIntegration,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.condicaoGeral,
                                      "RefIntegration",
                                      $$v
                                    )
                                  },
                                  expression: "condicaoGeral.RefIntegration",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "add-tag py-1",
                                  attrs: {
                                    type: "border",
                                    icon: "add",
                                    "icon-after": true,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTag(1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Incluir TAG\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        { staticClass: "mb-5" },
                        [
                          _c("vs-switch", {
                            model: {
                              value: _vm.condicaoGeral.EnableToProposal,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.condicaoGeral,
                                  "EnableToProposal",
                                  $$v
                                )
                              },
                              expression: "condicaoGeral.EnableToProposal",
                            },
                          }),
                          _c("label", { staticClass: "ml-2" }, [
                            _vm._v(" Habilitar na Proposta"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            [
                              _c("jodit-editor", {
                                attrs: {
                                  id: "condicoes",
                                  config: _vm.joditConfig,
                                },
                                model: {
                                  value: _vm.condicaoGeral.Value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.condicaoGeral, "Value", $$v)
                                  },
                                  expression: "condicaoGeral.Value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingButton,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveObject(7)
                                    },
                                  },
                                },
                                [_vm._v("Salvar Condições Gerais")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "template",
                    { slot: "tab-content-3" },
                    [
                      _vm._l(_vm.condition.Tags, function (item, index) {
                        return _c(
                          "vs-row",
                          { key: index },
                          [
                            _c(
                              "vs-col",
                              { staticClass: "md:w-5/12 w-full" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { label: "Descrição" },
                                  model: {
                                    value: item.Label,
                                    callback: function ($$v) {
                                      _vm.$set(item, "Label", $$v)
                                    },
                                    expression: "item.Label",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { staticClass: "md:w-6/12 w-full pb-5" },
                              [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: { label: "Tag", value: item.Name },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setName(
                                        item,
                                        $event.target.value,
                                        4
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              { staticClass: "md:w-1/12 w-full m-auto" },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "flat",
                                    icon: "close",
                                    size: "large",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.removeTag(item, index, 4)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { staticClass: "md:w-5/12 w-full" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: "Referência da seguradora" },
                                model: {
                                  value: _vm.condition.RefIntegration,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.condition,
                                      "RefIntegration",
                                      $$v
                                    )
                                  },
                                  expression: "condition.RefIntegration",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "add-tag py-1",
                                  attrs: {
                                    type: "border",
                                    icon: "add",
                                    "icon-after": true,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTag(4)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Incluir TAG\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        { staticClass: "mb-5" },
                        [
                          _c("vs-switch", {
                            model: {
                              value: _vm.condition.EnableToProposal,
                              callback: function ($$v) {
                                _vm.$set(_vm.condition, "EnableToProposal", $$v)
                              },
                              expression: "condition.EnableToProposal",
                            },
                          }),
                          _c("label", { staticClass: "ml-2" }, [
                            _vm._v(" Habilitar na Proposta"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            [
                              _c("jodit-editor", {
                                attrs: {
                                  id: "condicoes",
                                  config: _vm.joditConfig,
                                },
                                model: {
                                  value: _vm.condition.Value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.condition, "Value", $$v)
                                  },
                                  expression: "condition.Value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: { "vs-type": "flex", "vs-justify": "end" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingButton,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveObject(4)
                                    },
                                  },
                                },
                                [_vm._v("Salvar Condições Especiais")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: "", active: _vm.modalDelete },
          on: {
            "update:active": function ($event) {
              _vm.modalDelete = $event
            },
          },
        },
        [
          _c(
            "vs-row",
            [
              _c("vs-col", { staticClass: "flex justify-center mb-10" }, [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          Deseja realmente " +
                      _vm._s(_vm.coverageIsDeleted ? "ativar" : "inativar") +
                      " a\n          cobertura "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.coverageName))]),
                  _vm._v("?\n        "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { type: "border" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelDelete()
                        },
                      },
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-3 button-primary",
                      on: {
                        click: function ($event) {
                          return _vm.confirmDelete(_vm.coverageId)
                        },
                      },
                    },
                    [_vm._v("\n          Confirmar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }